@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	height: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

a {
	text-decoration: none;
}
